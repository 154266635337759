import s from "./Updates.module.css"; // Предположим, что у вас есть стили для выделения
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import TopMenu from "../TopMenu/TopMenu";
import image from "../../assets/Buttons/star.png"

const Updates = () => {
  const [updates, setUpdates] = useState([]);
  const [userUpdates, setUserUpdates] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState("");

  const user = useSelector((state) => state.user);

  useEffect(() => {
    axios
      .post(`https://blogerverse.com/api/updates `, { category: category })
      .then((response) => {
        setUpdates(response.data); // Установка данных о всех улучшениях
      })
      .catch((error) => {
        console.error("Error fetching updates:", error);
      });
  }, [category]);

  // Получаем улучшения, которые уже купил пользователь
  useEffect(() => {
    axios
      .get(`https://blogerverse.com/api/user-updates/${user.id}`)
      .then((response) => {
        setUserUpdates(response.data);
      })
      .catch((error) => {
        console.error("Error fetching user updates:", error);
      });
  }, [user.id]);

  // Функция, которая проверяет, было ли улучшение куплено пользователем
  const isUpdatePurchased = (updateId) => {
    return userUpdates.includes(updateId);
  };
  const setUpdate = (updateId) => {
    axios
      .post(`https://blogerverse.com/api/buy-upgrade/`, {
        user_id: 5,
        update_id: updateId,
      })
      .then((response) => {
        setUserUpdates([...userUpdates, updateId]);
      })
      .catch((error) => {
        console.error("Error buying update:", error);
      });
  };

  return (
    <div
      style={{
        backgroundColor: "#24242e",
        height: "100dvh",
        width: "100vw",
      }}
    >
      <TopMenu />
      <div className={s.category}>
        <div className={s.categoryMargin}>
          <div
            className={!category ? s.categoryBtnActive : s.categoryBtn}
            onClick={() => setCategory("")}
          >
            All
          </div>
          <div
            className={
              category === "like" ? s.categoryBtnActive : s.categoryBtn
            }
            onClick={() => setCategory("like")}
          >
            Likes
          </div>
          <div
            className={
              category === "coin" ? s.categoryBtnActive : s.categoryBtn
            }
            onClick={() => setCategory("coin")}
          >
            Coins
          </div>
        </div>
      </div>
      <div></div>
      <div className={s.updatesListBlock}>
        <div className={s.updatesHeader}>
          <div>Update</div>
        </div>
        {updates.map((update) => {
          return (
            <div
              onClick={() => setUpdate(update.id)}
              className={
                isUpdatePurchased(update.id) ? s.updateBlockD : s.updateBlock
              }
            >
              <div>
                <img src={image} alt="img"/>
                <div>{update.name}</div>
              </div>
              <div>
                <div>
                  give: {update.update_coins}
                  give: {update.update_likes}
                  give: {update.update_likes}
                </div>
                <div>cost: {update.cost_likes}</div>
                <div>cost: {update.cost_money}</div>
              </div>
            </div>
          );
        })}
      </div>
      {/* <List
        className="demo-loadmore-list"
        loading={loading} // Загрузка
        itemLayout="horizontal"
        dataSource={updates} // Источник данных
        renderItem={(item) => (
          <List.Item
            actions={[<a key="list-loadmore-edit">Купить</a>]}
            style={{
              backgroundColor: isUpdatePurchased(item.id) ? "#24242e" : "white", // Цвет для выполненных обновлений
            }}
          >
            <Skeleton avatar title={false} loading={loading} active>
              <List.Item.Meta
                avatar={<Avatar icon={<BarsOutlined />} />}
                title={<a href="#">{item.name}</a>} // Ссылка на тип улучшения
                description={`${
                  item.cost_coins ? `${item.cost_coins} монет` : ""
                }
               ${item.cost_likes ? `${item.cost_likes} лайков ` : ""}
                ${item.cost_money ? `${item.cost_money} долларов ` : ""}`} // Описание улучшения
              />
            </Skeleton>
          </List.Item>
        )}
      /> */}
    </div>
  );
};

export default Updates;
