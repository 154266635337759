import React from 'react'
import s from "./OneQuest.module.css"
import videoP from "../../../assets/Buttons/video.png"
const OneQuest = ({id, name, mission_type, quest_set, status }) => {
  return <div className={(status === "not_started") ? s.block : ""}>
    <div>
    <img src={videoP}/>
    <div>

    {/* {id} */}
    </div>
    <div>
      {name}
    </div>
    <div>
      {mission_type}
    </div>
    </div>
  </div>
  
}

export default OneQuest