import s from "./Wardrobe.module.css";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Segmented } from "antd";
import { useDispatch, useSelector } from "react-redux";
import shoe from "../../assets/Widerobe/shoes.png"
import hair from "../../assets/Widerobe/hair.png"
import eye from "../../assets/Widerobe/eye.png"
import tshort from "../../assets/Widerobe/tshort.png"
import mouth from "../../assets/Widerobe/mouth2.png"
import pants from "../../assets/Widerobe/pants.png"
import {
  setWardrobeItem,
  setWardrobeUserData,
  setWardrobeItems,
} from "../../Redux/user/userReducer";

const setWardrobe = (id, value) => {
  return (dispatch, getState) => {
    dispatch(setWardrobeItem(value, id));
    const user = getState().user;
    axios
      .patch(`https://blogerverse.com/api/widerobe`, {
        user_id: user.id,
        eyes: user.eyes,
        hair: user.hair,
        mouth: user.mouth,
        dummy: user.dummy,
        body: user.body,
        legs: user.legs,
        shoes: user.shoes,
      })
      .then((res) => {
        dispatch(setWardrobeUserData(res.data.customisation));
        dispatch(setWardrobeItems(res.data.items));
      })
      .catch((error) => {
        console.error("Error updating wardrobe", error);
      });
  };
};

const Wardeobe = () => {
  const [value, setValue] = useState("hair");
  const [list, setList] = useState([]);
  const user = useSelector((state) => state.user);
  const containerRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(`https://blogerverse.com/api/user-items/${user.id}/?filter=unlocked&type=${value}`)
      .then((res) => {
        console.log(res.data);
        setList(res.data);
      });
  }, [value, user.id]);

  const handleUpdateWardrobe = ({ id }) => {
    dispatch(setWardrobe(id, value));
  };
  const preventImageDrag = (e) => {
    e.preventDefault();
};
const snapToCenter = () => {
  const container = containerRef.current;
  if (!container) return;

  const containerRect = container.getBoundingClientRect();
  const containerCenter = containerRect.width / 2 + 43;

  let closestElement = null;
  let closestDistance = Infinity;

  Array.from(container.children).forEach((child) => {
    const childRect = child.getBoundingClientRect();
    const childCenter = (childRect.left + childRect.right) / 2;
    const distance = Math.abs(containerCenter - childCenter);

    if (distance < closestDistance) {
      closestDistance = distance;
      closestElement = child;
    }
  });

  // Если элемент близок к центру (например, в пределах 70px), скроллим его в центр
  if (closestDistance <= 20) {
    const offset = closestElement.offsetLeft - (containerCenter - closestElement.offsetWidth / 2);
    container.scrollTo({
      left: offset,
      // behavior: "smooth",
    });
  }
};

// Обработчик события скролла
const handleScroll = () => {
  snapToCenter();
};
  return (
    <div
      className={s.wardeobeBlock}
      style={{
        // backgroundColor: "#24242e",
        
      }}
    >
      <Segmented
        className={s.sideWiderobe}
        value={value}
        vertical
        onChange={setValue}
        options={[
          {
            value: "hair",
            icon: <img src={hair} alt="img" />,
          },
          {
            value: "eyes",
            icon: <img src={eye} alt="img" />,
          },
          // {
          //   value: "iris",
          //   icon: <AppstoreOutlined />,
          // },

          {
            value: "mouth",
            icon: <img src={mouth} alt="img" />,
          },
          // {
          //   value: "dummy",
          //   icon: <AppstoreOutlined />,
          // },
          {
            value: "body",
            icon: <img src={tshort} alt="img" />,
          },
          {
            value: "legs",
            icon: <img src={pants} alt="img" />,
          },
          {
            value: "shoes",
            icon: <img src={shoe} alt="img" />,
          },
        ]}
      />
      <div className={s.infoBlock}
       ref={containerRef}
       onScroll={handleScroll}>
        {list.map((el) => (
          <div
            className={
              el.id === user.hair ||
              el.id === user.eyes ||
              el.id === user.mouth ||
              el.id === user.body ||
              el.id === user.legs ||
              el.id === user.shoes
                ? s.itemCustomActive
                : s.itemCustom
            }
            style={{ width: "6oopx" }}
            onClick={() => handleUpdateWardrobe(el)}
          >
            <img
              style={{ height: "60dvh" }}
              alt="item2" onDragStart={preventImageDrag}
              src={`https://blogerverse.com${el.img}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Wardeobe;
