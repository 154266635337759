import axios from "axios";
import React, { useState, useEffect } from "react";

const Friends = ({ userId = 5 }) => {
  const [friends, setFriends] = useState([]);
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    const fetchFriends = async () => {
      try {
        const response = await axios.get(`https://blogerverse.com/api/friendship?telegram_id=${userId}`);
        const { accepted, pending } = response.data;

        setFriends(accepted);
        setRequests(pending);
      } catch (error) {
        console.error("Ошибка при загрузке данных о друзьях:", error);
      }
    };

    fetchFriends();
  }, [userId]);

  const handleAccept = async (requestId) => {
    // Логика для подтверждения запроса на дружбу
    console.log(`Подтверждение запроса на дружбу с ID: ${requestId}`);
  };

  const handleReject = async (requestId) => {
    // Логика для отклонения запроса на дружбу
    console.log(`Отклонение запроса на дружбу с ID: ${requestId}`);
  };

  const calculateFriendshipDuration = (createdAt) => {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const duration = Math.floor((now - createdDate) / (1000 * 60 * 60 * 24)); // Разница в днях
    return `${duration} дней`;
  };

  return (
    <div>
      <h1>Friends</h1>
      <h2>Список друзей</h2>
      <ul>
        {friends.map((friend) => {
          const friendId = friend.from_user.telegram_id === userId ? friend.to_user.telegram_id : friend.from_user.id;
          return (
            <li key={friend.id}>
              Пользователь ID: {friendId}, Дружба длится: {calculateFriendshipDuration(friend.created_at)}
            </li>
          );
        })}
      </ul>

      <h2>Запросы на дружбу</h2>
      <ul>
        {requests.map((request) => (
          <li key={request.telegram_id}>
            Запрос от пользователя ID: {request.from_user.telegram_id}
            <button onClick={() => handleAccept(request.telegram_id)}>Подтвердить</button>
            <button onClick={() => handleReject(request.telegram_id)}>Отклонить</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Friends;