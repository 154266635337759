import React, { useState } from "react";
import DailyBonus from "./DailyQ.jsx";
import QuestComponent from "./QuestsList.jsx";
import PageStructure from "../PagesStructure/PageStructure.jsx";

const Quests = () => {
  const [category, setCategory] = useState("");
  const categories = [
    { name: "Quests", value: "" },
    { name: "Daily", value: "daily" },
    { name: "Special", value: "coin" },
  ];
  // Получаем все улучшения
  // useEffect(() => {
  //   axios
  //     .get(`http://127.0.0.1:8000/api/all-quests-user/5/ `)
  //     .then((response) => {
  //       setUpdates(response.data); // Установка данных о всех улучшениях
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching updates:", error);
  //       setLoading(false);
  //     });
  // }, [category]);

  // // Получаем улучшения, которые уже купил пользователь
  // useEffect(() => {
  //   axios
  //     .get(`https://blogerverse.com/api/user-updates/${1}`)
  //     .then((response) => {
  //       setUserUpdates(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching user updates:", error);
  //     });
  // }, []);

  // Функция, которая проверяет, было ли улучшение куплено пользователем

  return (
    // <div
    //   style={{
    //     height: "100dvh",
    //     width: "100vw",
    //   }}
    // >
    //   <TopMenu />
    //   <div
    //   className={s.page}
    //     style={{
    //       background:
    //         "linear-gradient(340deg, rgba(97,50,133,1) 16%, rgba(23,48,119,1) 43%, rgba(25,25,85,1) 76%)",

    //     }}
    //   >
    //     <div className={s.category}>
    //       <div className={s.categoryMargin}>
    //         <div
    //           className={!category ? s.categoryBtnActive : s.categoryBtn}
    //           onClick={() => setCategory("")}
    //         >
    //           Quests
    //         </div>
    //         <div
    //           className={
    //             category === "daily" ? s.categoryBtnActive : s.categoryBtn
    //           }
    //           onClick={() => setCategory("daily")}
    //         >
    //           Daily
    //         </div>
    //         <div
    //           className={
    //             category === "coin" ? s.categoryBtnActive : s.categoryBtn
    //           }
    //           onClick={() => setCategory("coin")}
    //         >
    //           Special
    //         </div>
    //       </div>
    //     </div>
    //     {category === "" && <QuestComponent />}
    //     {category === "daily" && <DailyBonus />}
    //   </div>
    // </div>
    <>
      <PageStructure
        categories={categories}
        category={category}
        setCategory={setCategory}
      >
        {category === "" && <QuestComponent />}
        {category === "daily" && <DailyBonus />}
      </PageStructure>
    </>
  );
};

export default Quests;
