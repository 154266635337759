import Footer from "./Components/Footer/Footer";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import { store } from "./Redux/store";
import MainPage from "./Components/Main";
import Updates from "./Components/Updates/Updates";
import Wardrobe from "./Components/Main/Wardrobe";
import Profile from "./Components/Profile/Profile";
import Quests from "./Components/Quests/Quests";
import Shop from "./Components/Shop/Shop";
import { retrieveLaunchParams } from "@telegram-apps/sdk";
import setUser from "./Redux/user/userReducer";
import axios from "axios";
import s from "./App.module.css";
import GenerateVideo from "./Components/VideoGenerate/VideoUser";
function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchLaunchParams = async () => {
      try {
        const { initData } = retrieveLaunchParams();
        if (initData && initData.user) {
          const res = await axios.post(
            "https://blogerverse.com/api/create-user/",
            {
              telegram_id: initData.user.id,
            }
          );
          console.log(res);
          dispatch(setUser(initData.user.id, initData.user.username));
        } else {
          console.warn("Telegram launch parameters are missing or incomplete.");
        }
      } catch (error) {
        axios
          .post("https://blogerverse.com/api/create-user/", {
            telegram_id: 5,
          })
          .then((res) => {
            dispatch(setUser(5, "Default"));
          });
      }
    };

    // Вызов асинхронной функции
    fetchLaunchParams();
  }, [dispatch]);
  // const user = useSelector((state) => state.user);

  const [activeWard, setActiveWard] = useState(false);

  // const [loading, setLoading] = useState(true);

  return (
    <div className={s.app}>
      <BrowserRouter>
        {activeWard && <Wardrobe />}
        <Routes>
          <Route element={<MainPage activeWard={activeWard} />} path="/" />
          <Route element={<Quests />} path="/quests" />
          <Route element={<Updates />} path="/upgrade" />
          <Route element={<Profile />} path="/profile" />
          <Route element={<Shop />} path="/shop" />
          <Route element={<GenerateVideo />} path="/video" />
        </Routes>
        <Footer setActiveWard={setActiveWard} activeWard={activeWard} />
      </BrowserRouter>
    </div>
  );
}

const BricsApp = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};
export default BricsApp;
