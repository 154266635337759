import React, { useState, useEffect } from "react";
import axios from "axios";
import s from "./Quests.module.css";

const DailyBonus = ({ telegram_id = 5 }) => {
  const [streak, setStreak] = useState(0);
  const [maxStreak, setMaxStreak] = useState(0);
  const [lastClaimed, setLastClaimed] = useState(null);
  const [bonuses, setBonuses] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    axios
      .get(`https://blogerverse.com/api/daily-bonus/${telegram_id}/`)
      .then((response) => {
        setStreak(response.data.current_streak);
        setLastClaimed(response.data.last_claimed);
        setMaxStreak(response.data.max_streak);
      })
      .catch((error) => {
        setError("Ошибка загрузки данных");
        console.error(error);
      });
  }, [telegram_id]);

  useEffect(() => {
    axios
      .get(`https://blogerverse.com/api/daily-bonus-all/`)
      .then((response) => {
        setBonuses(response.data);
      })
      .catch((error) => {
        setError("Ошибка загрузки данных");
        console.error(error);
      });
  }, [telegram_id]);

  const handleClaimBonus = () => {
    axios
      .post(`https://blogerverse.com/api/daily-bonus/${telegram_id}/`)
      .then((response) => {
        setStreak(response.data.current_streak);
        setLastClaimed(response.data.last_claimed);
        setBonuses(response.data.bonuses);
        
        setError("");
      })
      .catch((error) => {
        setError("Бонус уже был получен сегодня");
        console.error(error);
      });
  };

  return (
    <div className="daily-bonus-container">
      <h2>Ваш ежедневный бонус</h2>
      <p>Текущий стрик: {streak} дней</p>
      <p>Последний полученный бонус: {lastClaimed || "—"}</p>
      <button onClick={handleClaimBonus} className="bonus-button">
        Получить бонус
      </button>
      {error && <p className="error-message">{error}</p>}
      <div className={s.dailyList}>
        {bonuses.map((bonus, index) => (
          <div
            key={index}
            className={`${s.blockDay} ${
              maxStreak > index ? s.active : s.inactive
            }`}
          >
            <div style={{}}>Day: {bonus.day}</div>
            <span>{bonus.currency_type === "coin" ? "💰" : "💎"}</span>
            <span>{bonus.amount}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DailyBonus;
