import React from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import s from "./TopMenu.module.css";
import coin from "../../assets/Header/coin.png";
import heart from "../../assets/Header/heart.png";

const TopMenu = () => {
  const user = useSelector((state) => state.user);
  const stats = useSelector((state) => state.stats);
  return (
    <div
      className={s.upPageBlock}
      style={{
        height: "20dvh",
      }}
    >
      <div>
        <div className={s.stats}>
          <div className={s.imgBlock}>
            <img src={heart} />
            {stats.like_count}
          </div>
          <div className={s.income}>Income: {stats.income_like_second}</div>
        </div>
        <div className={s.stats}>
          <div className={s.imgBlock}>
            <img src={coin} />
            {stats.coin_count}
          </div>
          <div className={s.income}>Income: {stats.income_coin_second}</div>
        </div>
        <div className={s.user}>Пользователь: {user.username}</div>
      </div>
    </div>
  );
};

export default TopMenu;
