import s from "./Footer.module.css";
import {
  UserOutlined,
  DollarOutlined,
  SkinOutlined,

} from "@ant-design/icons";
import update from "../../assets/Buttons/upgrade.png"
import scene from "../../assets/Buttons/scene.png"
import profile from "../../assets/Buttons/Profile.png"
import backstage from "../../assets/Buttons/backstage.png"
import activities from "../../assets/Buttons/activities.png"
import { NavLink, useLocation } from "react-router-dom";

const Footer = ({ setActiveWard, activeWard }) => {
  const location = useLocation(); // Получаем текущий URL

  return (
    <div className={s.nav}>
      <NavLink to="/">
        <div
          onClick={() => {
            setActiveWard(false);
          }}
          className={location.pathname !== "/" || activeWard ? s.icon : s.iconA}
        >
          <img src={scene}/>
          <div className={s.name}>Scene</div>
        </div>
      </NavLink>
      <NavLink to="/upgrade">
        <div
          onClick={() => {
            setActiveWard(false);
          }}
          className={location.pathname !== "/upgrade" ? s.icon : s.iconA}
        >
          <img src={update}/>
          <div className={s.name}>Upgrade</div>
        </div>
      </NavLink>
      <NavLink to="/quests">
        <div
          onClick={() => {
            setActiveWard(false);
          }}
          className={location.pathname !== "/quests" ? s.icon : s.iconA}
        >
          <img src={activities}/>
          <div className={s.name}>Activities</div>
        </div>
      </NavLink>
      <NavLink to="/">
        <div
          className={!activeWard ? s.icon : s.iconA}
          onClick={() => {
            setActiveWard(!activeWard);
          }}
        >
          <img src={backstage}/>
          <div className={s.name}>Backstage</div>
        </div>
      </NavLink>
      <NavLink to="/profile">
        <div
          onClick={() => {
            setActiveWard(false);
          }}
          className={location.pathname !== "/profile" ? s.icon : s.iconA}
        >
          <img src={profile}/>
          <div className={s.name}>Profile</div>
        </div>
      </NavLink>
    </div>
  );
};

export default Footer;
