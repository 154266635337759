import React, { useState } from "react";
import PageStructure from "../PagesStructure/PageStructure";
import Friends from "./Friends/Friends";
import Top from "./Top/Top";

const Profile = () => {
  const [category, setCategory] = useState("");
  const categories = [
    { name: "About", value: "" },
    { name: "Friends", value: "daily" },
    { name: "Top", value: "coin" },
  ];
  // Получаем все улучшения
  // useEffect(() => {
  //   axios
  //     .get("https://blogerverse.com/api/clicks?id=1")
  //     .then((response) => {
  //       setUpdates(response.data); // Установка данных о всех улучшениях
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching updates:", error);
  //       setLoading(false);
  //     });
  // }, []);

  // Получаем улучшения, которые уже купил пользователь
  // useEffect(() => {
  //   axios
  //     .get(`https://blogerverse.com/api/char?id=1`)
  //     .then((response) => {
  //       setUserUpdates(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching user updates:", error);
  //     });
  // }, []);

  return (
    <>
      <PageStructure
        categories={categories}
        category={category}
        setCategory={setCategory}
      >
        {category === "" && <div>Profile</div>}
        {category === "daily" && <Friends />}
        {category === "coin" && <Top />}
      </PageStructure>
    </>
  );
};

export default Profile;
