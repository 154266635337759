import React, { useRef, useEffect } from "react";
import html2canvas from "html2canvas";
import { createFFmpeg } from "@ffmpeg/ffmpeg"; // Правильный импорт
import { Provider, useDispatch, useSelector } from "react-redux";
import axios from "axios";

const VideoUser = () => {

  const user = useSelector((state) => state.user);

  return (
    <svg height="600px" opacity={3} xmlns="http://www.w3.org/2000/svg">
      <image
        href={`https://blogerverse.com${user.dummyUrl}`}
        height="600px"
        width="200px"
      />
      <image
        href={`https://blogerverse.com${user.eyesUrl}`}
        height="600px"
        width="200px"
      />
      <image
        href={`https://blogerverse.com${user.hairUrl}`}
        height="600px"
        width="200px"
      />
      <image
        href={`https://blogerverse.com${user.bodyUrl}`}
        height="600px"
        width="200px"
      />
      <image
        href={`https://blogerverse.com${user.mouthUrl}`}
        height="600px"
        width="200px"
      />
      <image
        href={`https://blogerverse.com${user.legsUrl}`}
        height="600px"
        width="200px"
      />
      <image
        href={`https://blogerverse.com${user.shoesUrl}`}
        height="600px"
        width="200px"
      />
    </svg>
  );
};

const Background = () => {
  return (
    <div
      style={{
        width: "500px",
        height: "500px",
        background: "linear-gradient(90deg, red, blue)",
        animation: "backgroundAnimation 5s infinite alternate",
      }}
    />
  );
};

const AnimatedCharacter = () => {
  return (
    <div style={{ position: "relative", width: "500px", height: "500px" }}>
      <Background />
      <div style={{ position: "absolute", top: 0, left: 0 }}>
        <VideoUser />
      </div>
    </div>
  );
};



const GenerateVideo = () => {
  const containerRef = useRef(null);

  const captureFrames = async () => {
    const container = containerRef.current;
    const frames = [];
    const frameRate = 2;
    const duration = 5;
    const totalFrames = frameRate * duration;

    for (let i = 0; i < totalFrames; i++) {
      const canvas = await html2canvas(container);
      const dataUrl = canvas.toDataURL('image/png');
      frames.push(dataUrl);
      // console.log(`Кадр ${i + 1}:`, dataUrl);
      await new Promise((resolve) => setTimeout(resolve, 1000 / frameRate));
    }

    return frames;
  };

  const generateVideo = async () => {
    // console.log('Начало генерации видео');
    const frames = await captureFrames();

    const formData = new FormData();
    for (const [index, frame] of frames.entries()) {
      const blob = await (await fetch(frame)).blob();
      formData.append('frames', blob, `frame${index}.png`);
    }
    
    const response = await axios.post('http://127.0.0.1:8000/api/generate-video/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // console.log(response.data.message);
    // console.log('Сохраненный путь к видео:', response.data.video_file);

    // Отобразить видео на странице
    // const videoElement = document.createElement('video');
    // videoElement.src = `http://127.0.0.1:8000/${response.data.video_file}`;
    
    // videoElement.controls = true;
    // document.body.appendChild(videoElement);
  };

  useEffect(() => {
    generateVideo();
  }, []);

  return (
    <div>
      <div ref={containerRef} style={{ position: 'relative', width: '500px', height: '500px' }}>
        <Background />
        <div style={{ position: 'absolute', top: 0, left: 0 }}>
          <VideoUser />
        </div>
      </div>
    </div>
  );
};


export default GenerateVideo;
