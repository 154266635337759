import React from "react";
import TopMenu from "../TopMenu/TopMenu";
import s from "./PageStructure.module.css";

const PageStructure = ({ children, categories, category, setCategory }) => {
  return (
    <div className={s.page}>
      <TopMenu />
      <div className={s.pageContent}>
        <CategoriesPage
          categories={categories}
          category={category}
          setCategory={setCategory}
        />
        {children}
      </div>
    </div>
  );
};

const CategoriesPage = ({ categories, category, setCategory }) => {
  return (
    <div className={s.category}>
      <div className={s.categoryMargin}>
        {categories.map((cat) => (
          <div
            key={cat.value}
            className={
              category === cat.value ? s.categoryBtnActive : s.categoryBtn
            }
            onClick={() => setCategory(cat.value)}
          >
            {cat.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PageStructure;
