import s from "./Header.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import axios from "axios";
import coin from "../../assets/Header/coin.png";
import heart from "../../assets/Header/heart.png";
import { setStatsUserData } from "../../Redux/user/userStatsReducer";
import { Tooltip } from "antd";

const Header = () => {
  const stats = useSelector((state) => state.stats);
  const user = useSelector((state) => state.user);
  const [animations, setAnimations] = useState([]);
  const dispatch = useDispatch();

  // Загружаем данные при монтировании компонента
  useEffect(() => {
    user.id ?
    axios.get(`https://blogerverse.com/api/balance/${user.id}/`).then((res) => {
      dispatch(setStatsUserData(res.data));
    }) : axios.get(`https://blogerverse.com/api/balance/5/`).then((res) => {
      dispatch(setStatsUserData(res.data));
    })
  }, [user.id]);

  // Запускаем интервал для обновления like и coin каждую секунду
  useEffect(() => {
    const interval = setInterval(() => {
      incrementScore(stats);
    }, 1000);
    return () => clearInterval(interval); // очищаем интервал при размонтировании
  }, [stats]);

  const incrementScore = (stats) => {
    // Обновляем счетчики и диспатчим изменения в Redux
    const updatedStats = {
      ...stats,
      like_count: stats.like_count + stats.income_like_second,
      coin_count: stats.coin_count + stats.income_coin_second,
    };
    dispatch(setStatsUserData(updatedStats));

    // Анимация для дохода за секунду
    const newAnimation = (
      <span key={Date.now()} className={s.scoreAnimation}>
        +{stats.income_like_second}
      </span>
    );
    setAnimations((prevAnimations) => [...prevAnimations, newAnimation]);

    // Удаляем анимацию после 1 секунды
    setTimeout(() => {
      setAnimations((prevAnimations) => prevAnimations.slice(1));
    }, 1000);
  };
  const formatNumber = (num) => {
    if (num >= 1_000_000_000) return `${(num / 1_000_000_000).toFixed(1)}B`;
    if (num >= 1_000_000) return `${(num / 1_000_000).toFixed(1)}M`;
    if (num >= 1_000) return `${(num / 1_000).toFixed(1)}K`;
    return num.toString();
  };

  return (
    <header className={s.header}>
      <div className={s.score}>
        <Counter score={formatNumber(stats.like_count)} scoreF={stats.like_count} animations={animations} />
        <Counter name="money" scoreF={stats.coin_count} score={formatNumber(stats.coin_count)} />
      </div>
    </header>
  );
};

const Counter = ({ name = "likes", score, scoreF, animations = [] }) => {
  return (
    <Tooltip placement="bottom" title={scoreF} overlayInnerStyle={{ fontSize: "20px" }} color={"magenta"}>
      <div className={s.counter}>
        <div className={`${s.scoreContainer} ${s.moving}`}>
        {name === "likes" ? (
          <img  style={{ marginRight: "5px", height: "30px" }} src={heart} alt="heart" />
        ) : (
          <img  style={{ marginRight: "5px", height: "30px" }} src={coin} alt="coin" />
        )}
          <span className={s.score}>{score}</span>
        </div>
      </div>
    </Tooltip>
  );
}

export default Header;
