import s from "./Main.module.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setWardrobeUserData,
  setWardrobeItems,
} from "../../Redux/user/userReducer";
import shop from "../../assets/Buttons/shop.png";
import video from "../../assets/Buttons/video.png";
import friends from "../../assets/Buttons/friends.png";
import star from "../../assets/Buttons/star.png";
import Header from "../Header";
import { NavLink } from "react-router-dom";
import Joyride from 'react-joyride';


export const MainPage = ({ activeWard }) => {
  const user = useSelector((state) => state.user);
  useEffect(() => {}, [user]);
  return (
    <div className={!activeWard ? s.main : s.mainWiderobe}>
      <div>
        <Header />
        {/* {!activeProfile && !activeUpdates && !activeWard && <MainBtn />} */}
        <Person activeWard={activeWard} />
      </div>
    </div>
  );
};

const Person = ({ activeWard }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [timeLeft, setTimeLeft] = useState(10000);
  const [run, setRun] = useState(false);
  // const [timeLeft2, setTimeLeft2] = useState(1000);
  // const [smallTimer, setSmallTimer] = useState(0);
  const [isRunning, setIsRunning] = useState(true);

  // Обратный отсчёт основного таймера
  useEffect(() => {
    if (timeLeft <= 0) {
      setIsRunning(false);
      // onComplete();
      return;
    }
    const timerId = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft]);
  const steps = [
    {
      target: '.first',
      content: 'This is my awesome feature!',
      disableBeacon: true,
    },
    {
      target: '.second',
      content: 'This another awesome feature!',
    },
  ]
  useEffect(() => {
    setRun(true); // Запускает Joyride при загрузке страницы
  }, []);
  // Индикатор обновления (маленький таймер)
  // useEffect(() => {
  //   if (!isRunning) return;

  //   const smallTimerId = setInterval(() => {
  //     setSmallTimer((prev) => (prev + 1) % 10); // цикл обновления до 10
  //   }, 1000);

  //   return () => clearInterval(smallTimerId);
  // }, [isRunning]);

  // Форматирование времени в минуты:секунды
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };
  useEffect(() => {
    user.id
      ? axios
          .get(`https://blogerverse.com/api/usercustomlist?id=${user.id}`)
          .then((res) => {
            let customId = res.data.customisation;
            dispatch(setWardrobeUserData(customId));
            dispatch(setWardrobeItems(res.data.items));
          })
      : axios
          .get(`https://blogerverse.com/api/usercustomlist?id=5`)
          .then((res) => {
            let customId = res.data.customisation;
            dispatch(setWardrobeUserData(customId));
            dispatch(setWardrobeItems(res.data.items));
          });
  }, [user.id, dispatch]);

  return (
    <div >
      <Joyride
        continuous
        run={run}
        // scrollOffset={64}
        // scrollToFirstStep
        // showProgress
        // showSkipButton
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      {!activeWard && <div className={s.leftIcons}>
        <img src={video} alt="img" />
        <div className={s.timer}>{isRunning ? formatTime(timeLeft) : ""}</div>
        <img src={star} alt="img" />
        <div className={s.timer}>
          {/* {isRunning ? formatTime(timeLeft2) : ""} */}
        </div>
      </div>}
      <div className={s.person}>
        <img
          style={{
            zIndex: "1",
            opacity: `${activeWard ? 0.7 : 1}`,
          }} alt="img"
          src={`https://blogerverse.com${user.dummyUrl}`}
        />
        <img
          style={{
            zIndex: "2",
            opacity: `${activeWard ? 0.8 : 1}`,
          }} alt="img"
          src={`https://blogerverse.com${user.eyesUrl}`}
        />
        <img
          style={{
            zIndex: "3",
            opacity: `${activeWard ? 0.8 : 1}`,
          }} alt="img"
          src={`https://blogerverse.com${user.hairUrl}`}
        />
        <img
          style={{
            zIndex: "3",
            opacity: `${activeWard ? 0.8 : 1}`,
          }} alt="img"
          src={`https://blogerverse.com${user.bodyUrl}`}
        />
        <img
          style={{
            zIndex: "2",
            opacity: `${activeWard ? 0.8 : 1}`,
          }} alt="img"
          src={`https://blogerverse.com${user.mouthUrl}`}
        />
        <img
          style={{
            zIndex: "2",
            opacity: `${activeWard ? 0.85 : 1}`,
          }} alt="img"
          src={`https://blogerverse.com${user.legsUrl}`}
        />
        <img
          style={{
            zIndex: "1",
            opacity: `${activeWard ? 0.7 : 1}`,
          }} alt="img"
          src={`https://blogerverse.com${user.shoesUrl}`}
        />
      </div>
      {!activeWard &&
      <div  className={s.rightIcons}>
        <NavLink  to="/shop">
          <img className={"first"} src={shop} alt="img" />
        </NavLink>
        <NavLink  to="/shop">
        <img className={"second"} src={friends} alt="img" />
        </NavLink>
      </div>}
    </div>
  );
};

// const MainBtn = () => {
//   function registrateUser() {
//     axios.post("https://blogerverse.com/api/create-user/");
//   }
//   return (
//     <div style={{ margin: "0px auto", display: "flex" }}>
//       <button className={s.mainBtn} size="medium" style={{ margin: "0 auto" }}>
//         Создать видео
//       </button>
//     </div>
//   );
// };

export default MainPage;
