import React, { useState, useEffect } from "react";
import axios from "axios";
import s from "./Shop.module.css";
// import { useSelector } from "react-redux";

const Shop = () => {
  const [items, setItems] = useState([]);
  const [error, setError] = useState("");
  const [filter, setFilter] = useState("");
  const [value, setValue] = useState("shoes"); // Замените 'default' на нужное значение
  // const user = useSelector((state) => state.user);

  useEffect(() => {
    axios
      .get(
        `https://blogerverse.com/api/user-items/5/?filter=locked&type=${value}`
      )
      .then((response) => {
        
        setItems(response.data);
      })
      .catch((error) => {
        setError("Ошибка загрузки данных");
        console.error(error);
      });
  }, [value]);

  return (
    <div className={s.container}>
      <div className={s.filter}>
        <div className={s.categories}>
          <span
            onClick={() => setFilter("shoes")}
            style={filter === "shoes" ? { color: "#00B4C0" } : { color: "white" }}
            className={s.project}
          >
             Shoes
          </span>
          <span
            onClick={() => setValue("eyes")}
            style={
              filter === "eyes"
                ? { color: "#00B4C0" }
                : { color: "white" }
            }
            className={s.edu}
          >
             Eyes
          </span>
          <span
            onClick={() => setValue("hair")}
            style={
              filter === "hair" ? { color: "#00B4C0" } : { color: "white" }
            }
            className={s.science}
          >
            Hair
        </span>
          <span
            onClick={() => setValue("молодёжное")}
            style={
              filter === "молодёжное"
                ? { color: "#00B4C0" }
                : { color: "white" }
            }
            className={s.teen}
          >
            Youth cooperation
          </span>
        </div>
      </div>
      <div className={s.content}>
        {items.map((item, index) => (
          <div key={index} className={s.item}>
            <img src={`https://blogerverse.com${item.imgShop}`} alt={item.name} />
            <div>{item.name}</div>
          </div>
        ))}
        {error && <div className={s.error}>{error}</div>}
      </div>
    </div>
  );
};

export default Shop;
